<template>
    <v-card>
        <v-card-title>
            <div>Linked accounts</div>
            <v-spacer></v-spacer>
            <v-btn x-small fab @click="dialog = true">
                <v-icon>fal fa-plus</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader
                :loading="loading"
                transition="scale-transition"
                type="list-item-avatar-two-line"
            >
                <template v-if="accounts.length">
                    <v-list>
                        <v-list-item v-for="account in accounts" :key="account.id">
                            <v-list-item-avatar>
                                <v-icon color="red">fab fa-microsoft</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ account.brand }}</v-list-item-title>
                                <v-list-item-subtitle>{{ account.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon x-small @click="unlink(account)">
                                    <v-icon dense>fal fa-trash-alt fa-fw</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </template>
                <div v-else>You have no linked accounts.</div>
            </v-skeleton-loader>
        </v-card-text>
        <v-dialog v-model="dialog" width="300">
            <v-card>
                <v-card-title>Link account</v-card-title>
                <v-card-text>
                    <office365-login-button @success="handleOffice365">
                        <v-icon left dark>mdi-microsoft-office</v-icon>
                        Office 365
                    </office365-login-button>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import Http from '@/utils/httpClient';

export default {
    components: {
        Office365LoginButton: () => import('@/components/Office365LoginButton.vue')
    },
    data() {
        return {
            dialog: false,
            loading: false,
            accounts: []
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading = true;
            Http.get('contact/third-party-accounts')
                .then(({ data }) => {
                    this.loading = false;
                    this.accounts = data;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        handleOffice365(data) {
            this.dialog = false;
            this.$root.$loading.open({
                text: 'Linking your Office 365 account...'
            });
            Http.post('contact/third-party-accounts', {
                id: data.idTokenClaims.oid,
                email: data.idTokenClaims.preferred_username,
                brand: 'Office 365'
            })
                .then(() => {
                    this.$root.$loading.close();
                    this.$root.$snackbar.open({
                        text: 'Successfully linked.',
                        type: 'success'
                    });
                    this.init();
                })
                .catch(({ response }) => {
                    this.$root.$loading.close();

                    let error = response.data.message;

                    if (response.data.errors && response.data.errors.id) {
                        error = `Your Office 365 account ${data.idTokenClaims.preferred_username} is already linked to this account.`;
                    }

                    this.$root.$snackbar.open({
                        text: error,
                        type: 'error',
                        timeout: -1
                    });
                });
        },
        unlink(account) {
            this.$root
                .$confirm({
                    title: 'Are you sure?',
                    message: `You are about to unlink ${account.email} to this account.`,
                    agreeButtonText: 'Yes, unlink it'
                })
                .then(() => {
                    this.$root.$loading.open({
                        text: 'Unlinking account...'
                    });
                    Http.delete(`contact/third-party-accounts/${account.id}`)
                        .then(() => {
                            this.$root.$loading.close();
                            this.$root.$snackbar.open({
                                text: 'Successfully unlinked.',
                                type: 'success'
                            });
                            this.init();
                        })
                        .catch(({ response }) => {
                            this.$root.$loading.close();
                            this.$root.$snackbar.open({
                                text: response.data.message,
                                type: 'error',
                                timeout: -1
                            });
                        });
                });
        }
    }
};
</script>
